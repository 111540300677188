



































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Promo from "@/models/Promo";
import PromoService from "@/services/PromoService";
import LangModule from "@/store/LangModule";

@Component
export default class PromoView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	promo: Promo = new Promo()
	loading: boolean = false
	file: File | null = null

	created() {
		this.refresh()
	}

	refresh() {
		PromoService.getPromo(this, Number(this.$route.params.promoId))
		this.file = null
	}

	updatePromo() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.promoUpdateQuestion,
				 () => PromoService.patchPromo(this, Number(this.$route.params.promoId), this.promo, this.file)
			))
		}
	}

	deletePromo() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.promoDeleteQuestion,
			 () => PromoService.deletePromo(this, this.promo.id!)
		))
	}

	openUrl(url: string) {
		window.open(url, "_blank");
	}

}
